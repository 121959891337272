import React, { useEffect, useMemo, useState } from 'react'
import { PageProps } from 'gatsby'
import SVG from 'react-inlinesvg'

import { EmailNotificationLayout } from '../../../components/email-notification'
import { varClass } from '../../../components/shared'

export default function CompletedUpdateNotification(props: PageProps) {

  const [type, setType] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setType(params?.get('type') ?? '')
  }, [])

  const data = useMemo(() => {
    switch (type) {
      case '2':
        return {
          title: 'Powerful tool is a couple clicks away from feature',
          img: '/images/email-notifications/complete-update/1-2.svg',
          desc: 'Complete your upgrade and unlock more exclusive features, and deeper customisation.',
          features: ['Benefit', 'Benefit', 'Benefit', 'Benefit', 'Benefit'],
          featureIcon: '/images/email-notifications/complete-update/feature-icon.svg',
        }
      case '3':
        return {
          title: 'Powerful tool is a couple clicks away from feature',
          img: '/images/email-notifications/complete-update/3.svg',
          desc: 'Complete your upgrade and unlock more exclusive features, and deeper customisation.',
          features: [],
          featureIcon: ''
        }
      default:
        return {
          title: 'Powerful tool is a couple clicks away from feature',
          img: '/images/email-notifications/complete-update/1-2.svg',
          desc: 'Complete your upgrade and unlock more exclusive features, and deeper customisation.',
          features: [],
          featureIcon: '',
        }
    }
  }, [type])
  return <>
    <EmailNotificationLayout hasUnsubscribe={type==='3'}>
      <div className='complete-update column column-fill column-middle column-center'>
        <div className='column gap-6 column-middle column-center'>
          <h1 className='text-bold text-dark text-largiestest text-center m-y-0 max-w-374'>{data.title}</h1>
          <div className={
            varClass({
              'row row-center row-middle': true,
              'wrapper-feature-image': type !== '3'
            })
          }>
            <div className={varClass({
              'row row-center row-middle': true,
              'feature-image': type !== '3'
            })}>
              <SVG src={data.img} />
            </div>
          </div>
          <p className='text-bold text-big text-dark text-center m-y-0 max-w-333'>
            {data.desc}
          </p>
        </div>
        <div className='m-t-20'>
          <button className='btn btn-primary p-3 text-smaller text-bold'>Get "Feature” name</button>
        </div>
        {!!data.features.length &&
          <div className='m-t-40 column gap-2'>
            <h2 className='text-bolder text-big text-dark m-y-0'>Your new features</h2>
            {data.features.map((feature, index) =>
                <div key={feature + index} className='row row-fill row-center'>
                  <SVG width={14} height={14} src={data.featureIcon} />
                  <span className='text-dark text-9-3'>{feature}</span>
                </div>
              )
            }
          </div>
        }
      </div>
    </EmailNotificationLayout>
  </>
}