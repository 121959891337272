import React from 'react'
import SVG from 'react-inlinesvg'

import { LeftSidebar } from './left-sidebar'
import { Footer } from './footer'

export const EmailNotificationLayout = (props) => {
  const { hasUnsubscribe } = props
  return <>
    <div className='email-notification-layout row row-fill background-white'>
      <LeftSidebar />
      <div className='email-notification-layout-content column gap-60 column-space-between'>
        <div className='row row-middle m-t-30'>
          <SVG src='/images/email-notifications/heylink.svg' />
        </div>
        <div className='row row-middle'>
          {props.children}
        </div>
        <div className='row row-middle m-b-6'>
          <Footer hasUnsubscribe={hasUnsubscribe} />
        </div>
      </div>
    </div>
  </>
}