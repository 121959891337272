import React from 'react'
import SVG from 'react-inlinesvg'

export const Footer = (props) => {
  const { hasUnsubscribe } = props
  return <div className='email-notification-footer row row-center row-middle p-y-7'>
    <div className="column gap-20 column-center column-middle">
      <SVG src='/images/email-notifications/heylink-small-white.svg' />
      <div className='row row-nowrap gap-20'>
        <span>Log in</span>
        <span>Help center</span>
        {hasUnsubscribe && <span>Unsubscribe</span>}
        <span>Blog</span>
      </div>
      <SVG src='/images/email-notifications/linkedin.svg' />
    </div>
  </div>
}